import { InjectionToken } from '@angular/core';
import { DeepReadonly, LanguageCodeString } from '../types';

export const ENVIRONMENT = new InjectionToken('environment');

interface _IEnvironment {
  stage: EnvironmentStage;
  version: SemanticVersionString;
  i18n: {
    availableLanguages: Language[];
    defaultLanguageCode: LanguageCodeString;
  };
}

export type IEnvironment = DeepReadonly<_IEnvironment>;

export enum EnvironmentStage {
  LOCAL,
  QA,
  PRODUCTION,
}

export type SemanticVersionString = `${number}.${number}.${number}` | `${number}.${number}.${number}-${string}`;

export interface Language {
  readonly name: string;
  readonly code: LanguageCodeString;
  selected?: boolean;
}
